<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0"></ol>
            <router-link
              :to="{
                name: 'monthly-plan-enroll',
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title">Training Enrollment</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <place-holder v-if="loading"></place-holder>
    <div class="row" v-show="!loading">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-6 custom-border">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module</span>
                              </th>
                              <td>
                                <span class="ms-1 custom-block">{{
                                  module_name
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Employee Type</span>
                              </th>
                              <td>
                                <span class="ms-1">{{ employee_type }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr" v-if="selected_module_name">
                              <th>
                                <span class="ms-1">Level</span>
                              </th>
                              <td>
                                <div
                                  class="ms-1"
                                  v-for="(name, index) in selected_module_name"
                                  :key="index"
                                >
                                  {{ name }}
                                </div>
                              </td>
                            </tr>
                            <tr class="custom-tr" v-if="selectedProgram">
                              <th>
                                <span class="ms-1">Program</span>
                              </th>
                              <td>
                                <div class="ms-1">
                                  {{ selectedProgram.short_name }}
                                </div>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Module Group</span>
                              </th>
                              <td>
                                <span class="ms-1 custom-block">{{
                                  module_group
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Trainer</span>
                              </th>
                              <td>
                                <!-- style="display: block" -->
                                <span
                                  class="ms-1 custom-block"
                                  v-for="(trainer, index) in trainers"
                                  :key="index"
                                  >{{ trainer.name }}
                                  <span v-if="index + 1 < trainers.length"
                                    >,
                                  </span>
                                </span>
                              </td>
                            </tr>

                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Learning Objective</span>
                              </th>
                              <td>
                                <span class="ms-1 custom-css">{{
                                  learning_objective
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Course Description</span>
                              </th>
                              <td>
                                <span class="ms-1 custom-css">{{
                                  description
                                }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                    <div class="col-xl-6">
                      <div class="table-responsive">
                        <table
                          class="table table-centered table-nowrap table-hover mb-0"
                        >
                          <tbody>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">From Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.from_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">To Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.to_date
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Enrolled Expired Date</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.expired_date
                                }}</span>
                              </td>
                            </tr>
                            <!-- <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Training Hour</span>
                              </th>
                              <td>
                                <span class="ms-1">{{ duration }}</span>
                              </td>
                            </tr> -->
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Start Time</span>
                              </th>
                              <td>
                                <span class="ms-1">{{
                                  monthly_plan.start_time
                                }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Price</span>
                              </th>
                              <td>
                                <span class="ms-1">{{ price }}</span>
                              </td>
                            </tr>
                            <tr class="custom-tr">
                              <th>
                                <span class="ms-1">Location</span>
                              </th>
                              <td>
                                <span class="ms-1 custom-block">{{
                                  monthly_plan.location
                                }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                style="cursor: not-allowed"
                v-if="monthly_plan.is_plan_confirmed == 4"
                data-bs-toggle="tooltip"
                title="Try Later! Training plan is cancelled."
              >
                <i class="mdi mdi-plus-circle"></i> Enroll
              </button>
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                style="cursor: not-allowed"
                v-else-if="monthly_plan.is_plan_confirmed == 0"
                data-bs-toggle="tooltip"
                title="Try Later ! Training plan is rejected because of less enrollment."
              >
                <i class="mdi mdi-plus-circle"></i> Enroll
              </button>
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                style="cursor: not-allowed"
                v-else-if="monthly_plan.expired_date < current_year_month"
                data-bs-toggle="tooltip"
                title="Your enrollment date is expired."
              >
                <i class="mdi mdi-plus-circle"></i> Enroll
              </button>
              <button
                v-else
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                @click="showModal(null, 'create')"
              >
                <i class="mdi mdi-plus-circle"></i> Enroll
              </button>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-centered mb-0">
                <thead class="table-light">
                  <tr>
                    <th>No</th>
                    <th>Employee Name</th>
                    <th>Employee Id</th>
                    <th>Position</th>
                    <th>Department</th>
                    <th>BU/BR/DIV</th>
                    <th>Approved By</th>
                    <th>Approved By HR</th>
                    <th style="width: 140px">Status</th>
                    <!-- <th>Remark</th> -->
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr
                    v-for="(enroll_list, index) in filterEmployees"
                    :key="index"
                  >
                    <td>{{ ++index }}</td>
                    <td>{{ enroll_list.name }}</td>
                    <td>{{ enroll_list.emp_id }}</td>
                    <td>
                      {{ enroll_list.position_name }}
                    </td>
                    <td>
                      {{ enroll_list.department_name }}
                    </td>
                    <td>
                      {{ enroll_list.business_unit_name }}
                    </td>
                    <td>
                      {{ enroll_list.approved_user }}
                    </td>
                    <td>
                      <span v-if="enroll_list.approved_by_hr">
                        {{ enroll_list.approvedHR_user }}</span
                      >
                      <span v-else></span>
                    </td>
                    <td
                      style="cursor: pointer"
                      v-if="
                        enroll_list.enrolled_user_id ==
                        $store.state.auth.user.employee_id
                      "
                      @click="showModal(enroll_list, 'edit')"
                    >
                      <span v-if="enroll_list.is_enroll_confirmed == 0"
                        >Rejected</span
                      >
                      <span v-else-if="enroll_list.is_enroll_confirmed == 1"
                        >Approved</span
                      >
                      <span v-else-if="enroll_list.is_enroll_confirmed == 2"
                        >Waiting</span
                      >
                      <span v-else-if="enroll_list.is_enroll_confirmed == 3"
                        >Canceled</span
                      >
                    </td>
                    <td
                      v-else
                      aria-disabled="true"
                      style="cursor: not-allowed"
                      data-bs-toggle="tooltip"
                      title="You are not an enrolled user!"
                    >
                      <span v-if="enroll_list.is_enroll_confirmed == 0"
                        >Rejected</span
                      >
                      <span v-else-if="enroll_list.is_enroll_confirmed == 1"
                        >Approved</span
                      >
                      <span v-else-if="enroll_list.is_enroll_confirmed == 2"
                        >Waiting</span
                      >
                      <span v-else-if="enroll_list.is_enroll_confirmed == 3"
                        >Canceled</span
                      >
                    </td>
                    <!-- <td>
                      {{ enroll_list.remark }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EnrollModal :modalId="'enroll-form-modal'" v-show="!loading">
    <template v-slot:header> Training Enroll Form </template>
    <template v-slot:body>
      <div class="card-body" v-if="!loading">
        <div class="mb-3">
          <label for="employee-name" class="form-label">Employee Name</label>
          <span class="text-danger">*</span>
          <v-select
            :disabled="formType == 'edit' ? true : false"
            @option:selected="getEmployee"
            v-model="selectdEmployee"
            id="trainer"
            label="name"
            :options="employees"
            :loading="empLoading"
            :clearable="false"
            :class="{
              'p-invalid': v$.selectdEmployee.$error || errorFor('name'),
            }"
          >
          </v-select>
          <v-errors
            :serverErrors="errorFor('name')"
            :vuelidateErrors="{
              errors: v$.selectdEmployee.$errors,
              value: 'Employee Name',
            }"
          ></v-errors>
        </div>
        <div class="mb-3">
          <label for="product-name" class="form-label">Employee Id </label>
          <input
            type="text"
            id="product-name"
            class="form-control"
            placeholder="Employee Id"
            v-model="employee.enrolledby_employee_id"
            disabled
            style="cursor: not-allowed"
          />
        </div>

        <div class="mb-3">
          <label for="product-reference" class="form-label"
            >Position Level
          </label>
          <input
            type="text"
            id="product-reference"
            class="form-control"
            placeholder="Position Level"
            v-model="employee.enrolledby_position_level"
            disabled
            style="cursor: not-allowed"
          />
        </div>
        <div class="mb-3">
          <label for="department">Department </label>
          <input
            type="text"
            class="form-control"
            id="department"
            placeholder="Department"
            v-model="employee.enrolledby_department"
            disabled
            style="cursor: not-allowed"
          />
        </div>
        <div class="mb-3">
          <label for="business-unit">BU/BR/DIV </label>
          <input
            type="text"
            class="form-control"
            id="business-unit"
            placeholder="BU/BR/DIV"
            v-model="employee.enrolledby_business_unit"
            disabled
            style="cursor: not-allowed"
          />
        </div>
        <div class="mb-3">
          <label for="product-category" class="form-label">Approved By </label>
          <span class="text-danger">*</span>
          <v-select
            :disabled="
              formType == 'edit' &&
              monthly_plan.expired_date < current_year_month &&
              enroll_list.is_enroll_confirmed != 2
                ? true
                : false
            "
            @option:selected="getApprover"
            v-model="selectdApproveEmployee"
            id="trainer"
            label="name"
            :options="employees"
            :selectable="
              (option) =>
                !option?.emp_id?.toString()?.includes(selectdEmployee?.emp_id)
            "
            :clearable="false"
            data-bs-toggle="tooltip"
            :title="
              monthly_plan.expired_date < current_year_month
                ? 'You can not change name after registration period or after change status!'
                : ''
            "
            :class="{
              'p-invalid': v$.selectdApproveEmployee.$error || errorFor('name'),
            }"
          >
          </v-select>
          <v-errors
            :serverErrors="errorFor('name')"
            :vuelidateErrors="{
              errors: v$.selectdApproveEmployee.$errors,
              value: 'Approved By',
            }"
          ></v-errors>
        </div>
        <div class="mb-3">
          <label for="email">Approved By Email </label>
          <!-- :disabled="formType == 'edit' ? true : false" -->
          <input
            type="text"
            class="form-control"
            id="email"
            placeholder="Approved By Email"
            v-model="employee.approveby_email"
            disabled
            style="cursor: not-allowed"
          />
        </div>

        <div class="mb-3">
          <label for="product-category" class="form-label"
            >Approved By HR</label
          >
          <!-- <span class="text-danger">*</span> -->
          <v-select
            :disabled="
              formType == 'edit' &&
              monthly_plan.expired_date < current_year_month &&
              enroll_list.is_enroll_confirmed != 2
                ? true
                : false
            "
            @option:selected="getApprovedHR"
            v-model="selectdApprovedHR"
            id="trainer"
            label="name"
            :options="employees"
            :selectable="
              (option) =>
                !option?.emp_id?.toString()?.includes(selectdEmployee?.emp_id)
            "
            :clearable="false"
            data-bs-toggle="tooltip"
            :title="
              monthly_plan.expired_date < current_year_month
                ? 'You can not change name after registration period or after change status!'
                : ''
            "
          >
          </v-select>
        </div>
        <div class="mb-3">
          <label for="email">Approved By HR Email </label>
          <input
            type="text"
            class="form-control"
            id="email"
            placeholder="Approved By Email"
            v-model="employee.approvebyhr_email"
            disabled
            style="cursor: not-allowed"
          />
        </div>

        <div class="mb-3" v-if="formType == 'edit'">
          <label for="product-category" class="form-label">Status </label>
          <v-select
            v-if="
              enroll_confirmed_status == 'Rejected' ||
              enroll_confirmed_status == 'Approved' ||
              enroll_confirmed_status == 'Canceled' ||
              monthly_plan.expired_date < current_year_month
            "
            id="vue-selectCss"
            v-model="enroll_confirmed_status"
            label="name"
            :options="enroll_confirm_status"
            :reduce="(name) => name.id"
            :clearable="false"
            disabled
            data-bs-toggle="tooltip"
            :title="
              enroll_confirmed_status == 'Rejected' ||
              enroll_confirmed_status == 'Approved' ||
              enroll_confirmed_status == 'Canceled'
                ? 'You can not change status after approved or rejected or canceled!'
                : 'You can not change status after registration period!'
            "
          >
          </v-select>
          <v-select
            v-else
            id="vue-selectCss"
            v-model="statusData"
            label="name"
            :options="enroll_confirm_status"
            :reduce="(name) => name.id"
            :clearable="false"
          >
          </v-select>
        </div>

        <div v-if="statusData == 3">
          <textarea
            v-model="canceled_remark"
            v-if="
              enroll_confirmed_status == 'Canceled' ||
              monthly_plan.expired_date < current_year_month
            "
            disabled
            data-bs-toggle="tooltip"
            :title="
              enroll_confirmed_status == 'Canceled'
                ? 'You can not change status after canceled!'
                : 'You can not change remark after registration period!'
            "
            type="text"
            id="canceled_remark"
            name="canceled_remark"
            class="form-control"
            placeholder="Please add remark"
          />
          <textarea
            v-else
            v-model="canceled_remark"
            type="text"
            id="canceled_remark"
            name="canceled_remark"
            class="form-control"
            placeholder="Please add remark"
          />
          <span
            v-if="canceled_remark == '' || canceled_remark == null"
            style="color: #f06464 !important; font-size: 12px"
            >Remark is required</span
          >
          <span v-else></span>
        </div>

        <div v-if="enroll_confirmed_status == 'Rejected'">
          <textarea
            v-model="canceled_remark"
            disabled
            data-bs-toggle="tooltip"
            :title="
              enroll_confirmed_status == 'Rejected'
                ? 'You can not change status after rejected!'
                : 'You can not change remark after registration period!'
            "
            type="text"
            id="canceled_remark"
            name="canceled_remark"
            class="form-control"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        class="btn w-sm btn-secondary me-4"
        data-bs-dismiss="modal"
      >
        Close
      </button>
      <button
        v-if="
          enroll_confirmed_status == 'Rejected' ||
          enroll_confirmed_status == 'Approved' ||
          monthly_plan.expired_date < current_year_month
        "
        type="button"
        class="btn btn-primary"
        style="cursor: not-allowed"
        data-bs-toggle="tooltip"
        :title="
          enroll_confirmed_status == 'Rejected' ||
          enroll_confirmed_status == 'Approved'
            ? 'You can not change status after approved or rejected!'
            : 'You can not change status after registration period!'
        "
      >
        Save
      </button>
      <button
        v-else
        :disabled="isLoading ? true : false"
        type="button"
        class="btn w-sm btn-success waves-effect waves-light"
        @click="
          formType == 'create' ? createMonthlyEnroll() : updateMonthlyEnroll()
        "
      >
        <span
          v-if="isLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ isLoading == true ? "Loading..." : "Save" }}
      </button>
    </template>
  </EnrollModal>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

import EnrollModal from "../../components/shared/Modal.vue";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      current_year_month: "",
      formType: "create",
      employees: [],
      monthly_plan: "",
      module_name: "",
      employee_type: "",
      module_group: "",
      selected_module_name: [],
      programs: [],
      selectedProgram: "",
      // duration: "",
      position_levels_id: [],
      price: "",
      trainers: "",
      learning_objective: "",
      description: "",
      statusData: "",
      enroll_confirmed_status: "",
      enroll_confirm_status: [
        {
          id: 2,
          name: "Waiting",
        },
        {
          id: 3,
          name: "Canceled",
        },
      ],
      employee: {
        enrolledby_name: "",
        enrolledby_user_id: "",
        enrolledby_employee_id: "",
        enrolledby_position_level: "",
        enrolledby_department: "",
        enrolledby_business_unit: "",
        enrolledby_email: "",
        approveby_name: "",
        approveby_email: "",
        approveby_employee_id: "",
        approveby_user_id: "",
        approvebyhr_name: "",
        approvebyhr_email: "",
        approvebyhr_employee_id: "",
        approvebyhr_user_id: "",
      },
      empLoading: true,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      selectdEmployee: "",
      selectdApproveEmployee: "",
      selectdApprovedHR: "",
      enroll_lists: "",
      employeeData: [],
      enrollIdData: "",
      employeeData_data: "",
      employeeDataId: "",
      enrollDataId: "",
      canceled_remark: "",

      filterEmployees: [],
      updateEnrollId: null,
    };
  },
  validations() {
    return {
      selectdEmployee: { required },
      selectdApproveEmployee: { required },
    };
  },
  methods: {
    async getEmployees() {
      this.employees = this.$store.getters["odoo/getAllActiveEmployees"];
      if (!this.employees || this.employees.length == 0) {
        this.empLoading = true;
        this.loading = true;
        setTimeout(() => {
          this.getEmployees();
        }, 1000);
      } else {
        this.empLoading = false;
        this.loading = false;
        await this.getAllEnrollLists();
      }
    },
    getEmployee() {
      this.loading = true;
      this.employee.employment_type = this.selectdEmployee.employment_type;
      this.employee.enrolledby_name = this.selectdEmployee.name;
      this.employee.enrolledby_user_id = this.selectdEmployee.id;
      this.employee.enrolledby_employee_id = this.selectdEmployee.emp_id;
      this.employee.enrolledby_position_level =
        this.selectdEmployee.position_level[0].name;
      this.employee.enrolledby_department =
        this.selectdEmployee.department[0].name;
      this.employee.enrolledby_business_unit =
        this.selectdEmployee.business_unit[0].name;
      this.employee.enrolledby_email = this.selectdEmployee.email;
      this.loading = false;
    },
    getApprover() {
      this.loading = true;
      this.employee.approveby_name = this.selectdApproveEmployee.name;
      this.employee.approveby_email = this.selectdApproveEmployee.email;
      this.employee.approveby_employee_id = this.selectdApproveEmployee.emp_id;
      this.employee.approveby_user_id = this.selectdApproveEmployee.id;
      this.loading = false;
    },

    getApprovedHR() {
      this.loading = true;
      this.employee.approvebyhr_name = this.selectdApprovedHR.name;
      this.employee.approvebyhr_email = this.selectdApprovedHR.email;
      this.employee.approvebyhr_employee_id = this.selectdApprovedHR.emp_id;
      this.employee.approvebyhr_user_id = this.selectdApprovedHR.id;
      this.loading = false;
    },

    async getAllEnrollLists() {
      await this.getMonthlyPlanDetail();
      // await this.getEmployees();
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/enroll-lists/${this.monthly_plan.id}`)
        .then((response) => {
          let enrollList = response.data.data;
          this.filterEmployees = enrollList.map((enroll) => {
            let employee = this.checklist(enroll.enrolled_user_id);
            let approved_user = this.checklist(enroll.approved_by);
            let approvedHR_user = this.checklist(enroll.approved_by_hr);
            let obj = {
              name: employee?.name,
              emp_id: employee?.emp_id,
              position_name: employee?.position_level[0]?.name,
              department_name: employee?.department[0]?.name,
              business_unit_name: employee?.business_unit[0]?.name,
              is_enroll_confirmed: enroll?.is_enroll_confirmed,
              remark: enroll?.remark,
              enroll_list_id: enroll?.id,
              enrolled_user_id: enroll?.enrolled_user_id,
              approved_by: enroll?.approved_by,
              requested_by: enroll?.requested_by,
              approved_user: approved_user?.name,
              approved_by_hr: enroll?.approved_by_hr,
              approvedHR_user: approvedHR_user?.name,
            };
            return obj;
          });

          this.loading = false;
          this.$Progress.finish();
        });
    },
    checklist(empId) {
      let resultArray;
      if (this.employees.length == 0) return;
      this.employees.forEach(function (emp) {
        if (emp.emp_id == empId) {
          resultArray = emp;
        }
      });
      return resultArray;
    },
    async getMonthlyPlanDetail() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v1/show-monthly-plan-detail/${this.$route.params.id}`
        )
        .then((response) => {
          this.monthly_plan = response.data.data;
          this.module_name = this.monthly_plan.module.name;
          this.employee_type = this.monthly_plan.employee_type.name;
          this.module_group = this.monthly_plan.group.name;
          // this.duration = this.monthly_plan.module.duration;
          this.price = this.monthly_plan.module.price;
          this.trainers = this.monthly_plan.trainers;
          this.learning_objective = this.monthly_plan.module.learning_objective;
          this.description = this.monthly_plan.module.description;

          const level = JSON.parse(this.monthly_plan.group.position_levels);

          // Create a new array to store the matching level names
          if (level == null || level == "" || level == []) {
            this.selected_module_name = null;
            this.selectedProgram = this.programs.find((item) => {
              if (item.id === this.monthly_plan.group.program_id) {
                return item;
              }
            });
          } else {
            this.selectedProgram = "";
            const matchingNames = level.map((id) => {
              const foundLevel = this.position_levels_id.find(
                (item) => item.id === id
              );
              return foundLevel ? foundLevel.name : null;
            });
            // Filter out any null values (in case an id in positionlevel doesn't exist in level)
            this.selected_module_name = matchingNames.filter(
              (name) => name !== null
            );

            // Join the names into a comma-separated string

            // const resultString = filteredNames.join(",");
            // this.selected_module_name = resultString;
          }

          this.loading = false;
        });
    },
    async getPositionLevels() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/position/level`)
        .then((response) => {
          this.position_levels_id = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Position Levels!");
        });
      this.loading = false;
    },
    async getPrograms() {
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async showModal(enroll_list, form) {
      this.cleanFormDatas();
      this.formType = form;
      if (form == "edit") {
        this.statusData = enroll_list.is_enroll_confirmed;
        if (this.statusData == 0) {
          this.enroll_confirmed_status = "Rejected";
        } else if (this.statusData == 1) {
          this.enroll_confirmed_status = "Approved";
        } else if (this.statusData == 2) {
          this.enroll_confirmed_status = "Waiting";
        } else if (this.statusData == 3) {
          this.enroll_confirmed_status = "Canceled";
        }
        this.canceled_remark = enroll_list.remark;
        this.updateEnrollId = enroll_list.enroll_list_id;
        this.getEmployeeById(enroll_list.enrolled_user_id);
        this.getApproverById(enroll_list.approved_by);
        if (enroll_list.approved_by_hr) {
          this.getApprovedByHR(enroll_list.approved_by_hr);
        }
      }

      $("#enroll-form-modal").modal("show");
    },

    cleanFormDatas() {
      this.selectdEmployee = null;
      this.selectdApproveEmployee = null;
      this.selectdApprovedHR = null;
      this.employee = {};
      this.enroll_list = "";
      this.statusData = "";
      this.enroll_confirmed_status = "";
      this.v$.selectdEmployee = "";
      this.v$.selectdApproveEmployee = "";
    },

    async getApproverById(id) {
      await axios
        .post(`${this.baseUrlHRIS}api/employee/search/${id}`)
        .then((response) => {
          this.selectdApproveEmployee = response.data.data[0];
          this.employee.approveby_name = this.selectdApproveEmployee.name;
          this.employee.approveby_email =
            this.selectdApproveEmployee.work_email;
          this.employee.approveby_employee_id =
            this.selectdApproveEmployee.emp_id;
          this.employee.approveby_user_id = this.selectdApproveEmployee.id;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getApprovedByHR(id) {
      await axios
        .post(`${this.baseUrlHRIS}api/employee/search/${id}`)
        .then((response) => {
          this.selectdApprovedHR = response.data.data[0];
          this.employee.approvebyhr_name = this.selectdApprovedHR.name;
          this.employee.approvebyhr_email = this.selectdApprovedHR.work_email;
          this.employee.approvebyhr_employee_id = this.selectdApprovedHR.emp_id;
          this.employee.approvebyhr_user_id = this.selectdApprovedHR.id;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getEmployeeById(id) {
      await axios
        .post(`${this.baseUrlHRIS}api/employee/search/${id}`)
        .then((response) => {
          this.selectdEmployee = response.data.data[0];
          this.employee.enrolledby_name = this.selectdEmployee.name;
          this.employee.enrolledby_user_id = this.selectdEmployee.id;
          this.employee.enrolledby_employee_id = this.selectdEmployee.emp_id;
          this.employee.enrolledby_position_level =
            this.selectdEmployee.position_level_id[1];
          this.employee.enrolledby_department =
            this.selectdEmployee.department_id[1];
          this.employee.enrolledby_business_unit =
            this.selectdEmployee.holding_id[1];
          this.employee.enrolledby_email = this.selectdEmployee.work_email;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async createMonthlyEnroll() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      if (
        this.employee_type?.toLowerCase() == "normal" &&
        this.employee?.employment_type?.toLowerCase() != "regular" &&
        this.employee?.employment_type?.toLowerCase() != "permanent" &&
        this.employee?.employment_type?.toLowerCase() != "contract" &&
        this.employee?.employment_type?.toLowerCase() != "program"
      ) {
        return this.toast.error("Employee can't enroll for this training.");
      }
      if (
        this.employee_type?.toLowerCase() == "program" &&
        this.employee?.employment_type?.toLowerCase() != "program"
      ) {
        return this.toast.error(
          "Normal Employee can't enroll for this training."
        );
      }
      if (
        this.employee.enrolledby_employee_id == false ||
        this.employee.enrolledby_employee_id == null
      ) {
        this.toast.error("This User Cannot Enroll!");
        return;
      }

      if (
        this.employee_type == "Normal" &&
        !this.selected_module_name?.includes(
          this.employee.enrolledby_position_level
        )
      ) return this.toast.error("You can't enroll this class.");

        this.employee.is_enroll_confirmed = this.statusData;
      this.employee.monthly_plan_id = this.$route.params.id;
      this.employee.requestedby_id = this.$store.state.auth.user.employee_id;
      this.isLoading = true;
      await axios
        .post(`${this.baseUrl}admin/v1/enroll-lists`, this.employee)
        .then(() => {
          this.toast.success("Successfully Enrolled!");
          $("#enroll-form-modal").modal("hide");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error(this.errors.data.message);
          $("#enroll-form-modal").modal("hide");
          this.isLoading = false;
        });
      this.getEmployees();
      this.getAllEnrollLists();
    },

    async updateMonthlyEnroll() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      if (
        this.employee_type?.toLowerCase() == "normal" &&
        this.employee?.employment_type?.toLowerCase() != "regular " &&
        this.employee?.employment_type?.toLowerCase() != "permanent" &&
        this.employee?.employment_type?.toLowerCase() != "contract" &&
        this.employee?.employment_type?.toLowerCase() != "program"
      )
        return this.toast.error("Employee can't enroll for this training.");
      if (
        this.employee_type?.toLowerCase() == "program" &&
        this.employee?.employment_type?.toLowerCase() != "program"
      )
        return this.toast.error(
          "Normal Employee can't enroll for this training."
        );

      if (this.statusData == 3) {
        if (this.canceled_remark == "" || this.canceled_remark == null) {
          return;
        }
      }

      let data = {
        approved_by: this.employee.approveby_employee_id,
        approved_by_hr: this.employee.approvebyhr_employee_id,
        is_enroll_confirmed: this.statusData,
        enroll_remark: this.canceled_remark,
      };
      this.isLoading = true;
      await axios
        .post(
          `${this.baseUrl}admin/v1/enroll-lists/${this.updateEnrollId}`,
          data
        )
        .then(() => {
          this.toast.success("Successfully Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error(this.errors.data.message);
          this.isLoading = false;
        });

      $("#enroll-form-modal").modal("hide");
      this.getAllEnrollLists();
    },
  },
  async created() {
    await this.getPositionLevels();
    await this.getEmployees();
    this.getPrograms();
    // await this.getAllEnrollLists();
    this.current_year_month = moment().format("YYYY-MM-DD");
  },
  components: {
    EnrollModal,
    vSelect,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
  background-color: #efefef;
  opacity: 1;
  cursor: not-allowed;
}

.custom-tr {
  border-style: hidden !important;
}

.card {
  border: 1px solid #ccc !important;
}

.table > :not(caption) > * > * {
  padding: 0.45rem 0.85rem;
}

.custom-border {
  border-right: solid #ccc !important;
  border-width: thin;
  /* height: 160px; */
  height: auto;
}

.custom-css {
  display: inline-block;
  /* max-width: 230px; */
  max-height: 85px;
  overflow-y: scroll;
  white-space: pre-line;
}

.custom-css::-webkit-scrollbar {
  width: 5px;
}

.custom-css::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-css::-webkit-scrollbar-thumb {
  background: #ccc;
}

.custom-block {
  display: block;
  white-space: pre-wrap;
}
</style>
